import algoliasearch from "algoliasearch"
import AutoLink from "components/AutoLink"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Configure,
  DynamicWidgets,
  Highlight,
  InfiniteHits,
  InstantSearch,
  RefinementList,
  SearchBox,
} from "react-instantsearch-hooks-web"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import theme from "styles/theme"
import "../../styles/algolia.css"

// Algolia config
const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Hit = ({
  hit,
  setModalOpen = null,
  setModalTitle = null,
  setMetadata = null,
  setModalUrl = null,
}) => {
  return (
    <AutoLink
      to={hit.url}
      title={hit.title}
      css={{
        textDecoration: "none",
        color: theme.colors.core.darkGrey,
        width: "100%",
      }}
    >
      <article
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "1rem",
          transition: "background-color 0.2s ease",
          borderLeftWidth: 6,
          borderLeftStyle: "solid",
          borderLeftColor: hit.featured
            ? theme.colors.core.darkPurple
            : theme.colors.core.lightGrey,
          "&:hover": {
            backgroundColor: "#F7F3FD",
          },
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "calc(100% - 100px)",
          }}
        >
          <Highlight
            attribute="title"
            hit={hit}
            css={{
              fontWeight: "500",
              width: "auto",
              fontFamily: '"SMAlgebraDisplay"',
              marginBottom: 12,
            }}
          />

          <div css={{ display: "flex", flexDirection: "column" }}>
            <Highlight
              attribute="authorNames"
              hit={hit}
              separator=", "
              css={{
                color: theme.colors.extendedUI.grey2,
                fontSize: "0.75rem",
              }}
            />
            <div css={{ display: "flex", flexDirection: "row" }}>
              {hit.conferenceJournal && (
                <Highlight
                  attribute="conferenceJournal"
                  hit={hit}
                  css={{
                    color: theme.colors.extendedUI.grey2,
                    fontSize: "0.75rem",
                  }}
                />
              )}
              <small
                css={{
                  marginLeft: "0.25rem",
                  color: theme.colors.extendedUI.grey2,
                }}
              >
                ({hit.publicationDate})
              </small>
            </div>
          </div>
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <button
            data-metadata={hit.metadata}
            onClick={(e) => {
              e.preventDefault()
              console.log("clicked lay abstract")
              setModalOpen(true)
              setModalTitle(hit.title)
              setMetadata(hit.metadata)
              setModalUrl(hit.url)
            }}
            title="Show Lay Abstract"
            css={{
              border: 0,
              background: "transparent",
              color: theme.colors.core.darkGrey,
              transition: "color 0.2s ease",
              position: "relative",
              cursor: "pointer",
              pointerEvents: "all",
              zIndex: 4,
              "&:hover": {
                color: theme.colors.core.darkPurple,
              },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M15 4H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992zM11 11h2v6h-2v-6zm0-4h2v2h-2V7z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </article>
    </AutoLink>
  )
}

Hit.propTypes = {
  hit: PropTypes.shape({
    authorNames: PropTypes.arrayOf(PropTypes.string),
    conferenceJournal: PropTypes.string,
    collections: PropTypes.arrayOf(PropTypes.string),
    featured: PropTypes.bool,
    objectID: PropTypes.string,
    publicationDate: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    metadata: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    __position: PropTypes.number,
    _highlightResult: PropTypes.shape({
      title: PropTypes.shape({
        value: PropTypes.string,
        matchedWords: PropTypes.arrayOf(PropTypes.string),
        matchLevel: PropTypes.string,
      }),
    }),
  }),
  setModalOpen: PropTypes.func,
  setModalTitle: PropTypes.func,
  setMetadata: PropTypes.func,
  setModalUrl: PropTypes.func,
}

const ResearchPapersCollection = ({ title }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [metadata, setMetadata] = useState(null)
  const [modalUrl, setModalUrl] = useState(null)

  return (
    <React.Fragment>
      <div
        css={{
          maxWidth: 1228 + 50,
          padding: "80px 25px",
          margin: "0 auto",
        }}
      >
        {title && (
          <h2 css={{ ...theme.h1, textAlign: "center", marginBottom: 36 }}>
            {title}
          </h2>
        )}
        <InstantSearch
          searchClient={searchClient}
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        >
          <Configure analytics={true} hitsPerPage={10} />
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gridTemplateRows: "auto 1fr",
              gridGap: "20px",
            }}
          >
            <div
              css={{
                gridColumnStart: "span 1",
                gridRow: "span 2",
                [theme.mq.mobilePortrait]: {
                  gridRow: "span 1",
                  gridColumnStart: "span 4",
                },
              }}
            >
              <h3 css={{ ...theme.h3, marginBottom: 36 }}>Filters</h3>
              <DynamicWidgets>
                <RefinementList
                  attribute="publicationDate"
                  sortBy={["name:desc"]}
                  css={{
                    marginBottom: 26,
                  }}
                />
              </DynamicWidgets>
            </div>

            <div
              css={{
                gridColumnStart: "4",
                [theme.mq.mobilePortrait]: {
                  gridColumnStart: "1",
                  gridColumn: "span 4",
                  gridRow: "span 1",
                },
              }}
            >
              <SearchBox />
            </div>

            <div
              css={{
                gridColumnStart: "2",
                gridColumnEnd: "5",
                gridRowStart: "2",
                [theme.mq.mobilePortrait]: {
                  gridColumnStart: "1",
                  gridRow: "span 1",
                },
              }}
            >
              <InfiniteHits
                hitComponent={({ hit }) => (
                  <Hit
                    setModalOpen={setModalOpen}
                    setModalTitle={setModalTitle}
                    setMetadata={setMetadata}
                    setModalUrl={setModalUrl}
                    hit={hit}
                  />
                )}
                showPrevious={false}
              />
            </div>
          </div>
        </InstantSearch>
      </div>
      <Modal
        center
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onEscKeyDown={() => setModalOpen(false)}
        animationDuration={200}
        contentLabel="Modal : paper Abstract"
        styles={{
          modal: {
            borderRadius: "4px",
            padding: "2rem",
            border: `solid 1px ${theme.colors.core.darkPurple}`,
          },
          closeButton: {
            fill: theme.colors.core.darkPurple,
          },
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h3
            css={{
              fontSize: "1rem",
              fontWeight: "500",
              fontFamily: '"SMAlgebraDisplay"',
              [theme.mq.mobilePortrait]: {
                fontSize: "1.25rem",
                marginBottom: "1rem",
              },
            }}
          >
            {modalTitle}
          </h3>
          <div
            css={{
              fontSize: "0.75rem",
              color: theme.colors.extendedUI.grey2,
              marginBottom: 0,
              [theme.mq.mobilePortrait]: {
                fontSize: "1rem",
              },
              "& p": {
                marginBottom: "1rem",
              },
            }}
          >
            <p>{metadata}</p>
          </div>
          <p
            css={{
              marginBottom: 0,
              marginTop: 0,
            }}
          >
            <AutoLink
              to={modalUrl}
              css={{
                color: theme.colors.core.darkPurple,
                fontSize: "0.75rem",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
                [theme.mq.mobilePortrait]: {
                  fontSize: "1rem",
                },
              }}
            >
              View Paper
            </AutoLink>
          </p>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ResearchPapersCollection

ResearchPapersCollection.propTypes = {
  title: PropTypes.string,
}

export const query = graphql`
  fragment ResearchPapersCollectionBlock on SanityResearchPapersCollection {
    title
  }
`
